import React from 'react'
import LogoSVG from '../public/logo.svg'
import useTranslation from '../translations/useTranslation'
import StickyBar from './StickyBar'

const SHOW_STICKY_BAR = process.env.NEXT_PUBLIC_SHOW_STICKY_BAR === 'true'

function getLinkWithLocale(locale) {
  if (locale === 'en') return '/'
  return `/${locale}`
}

export default function Header(props) {
  const { hideStickyBar } = props
  const { locale } = useTranslation()
  const link = getLinkWithLocale(locale)

  return (
    <>
      {!hideStickyBar && SHOW_STICKY_BAR && <StickyBar instrument={props.instrument} />}
      <header>
        <a href={link}>
          <LogoSVG id='logo' />
        </a>
      </header>
    </>
  )
}
