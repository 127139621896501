import React from 'react'
import styles from './StickyBar.module.scss'

// export default function StickyBar() {
//   return (
//     <div className={styles.container}>
//       <a href="https://guitareo.sjv.io/c/1289539/1276223/14650?sharedid=cool-button" target="_blank" rel="noopener noreferrer" className={styles.content}>
//         <div className={styles.coolButton}>Free Download</div>
//         {/* <img src='/so/FretboardCheatSheet_728x90.jpeg' alt='Fretboard Cheat Sheet' /> */}
//         {/* <img src='/so/bar-text-min.png' alt='Ultimate Midi Pack'  className={styles.text} loading="lazy" height="57px" width="152px" />
//                 <img src='/so/bar-pack-3d-with-sale-min.png' alt='Ultimate Midi Pack, limited time 70% off'  className={styles.pack} loading="lazy" height="70px" width="57px" />
//                 <img src='/so/bar-buy-column-min.png' alt='Placement-ready Chord progressions & melodies, by Sample Ocean, BUY NOW' loading="lazy" height="67px" width="151px" /> */}
//       </a>
//     </div>
//   )
// }

export default function StickyBar(props) {
  const { instrument } = props
  return (
    <div className={styles.container}>
      <a href={`https://quiz.guitar-tuner.org?type=${instrument ? instrument : 'acoustic-guitar'}`} target="_blank" rel="noopener noreferrer" className={styles.content}>
        <div className={styles.coolButton}>Take Quiz</div>
        {/* <img src='/so/FretboardCheatSheet_728x90.jpeg' alt='Fretboard Cheat Sheet' /> */}
        {/* <img src='/so/bar-text-min.png' alt='Ultimate Midi Pack'  className={styles.text} loading="lazy" height="57px" width="152px" />
                <img src='/so/bar-pack-3d-with-sale-min.png' alt='Ultimate Midi Pack, limited time 70% off'  className={styles.pack} loading="lazy" height="70px" width="57px" />
                <img src='/so/bar-buy-column-min.png' alt='Placement-ready Chord progressions & melodies, by Sample Ocean, BUY NOW' loading="lazy" height="67px" width="151px" /> */}
      </a>
    </div>
  )
}
