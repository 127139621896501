const acousticGuitarTuning = {
  howToUseText: {
    en: (
      <>
        <p>
          You can tune your acoustic guitar with a microphone or by ear. Tuning
          the guitar <strong>automatically with a microphone</strong> is much
          easier, faster, and is our recommended option. However, tuning your
          instrument by ear will improve your musical ear in the long term, and
          can be a valuable skill to learn for the moments when you are not
          online.
          <br />
          <br />
          To tune your <strong>acoustic guitar automatically:</strong>
          <br />
        </p>
        <ol>
          <li>
            Press the Microphone switch to tune the guitar automatically. Make
            sure to allow the website to use your microphone.
          </li>
          <li>
            Get your instrument close to your microphone to let the tuner
            recognize the string you’re playing. If <em>Detect String</em> is
            on, the acoustic guitar tuner will try to automatically identify the
            string you’re tuning. Alternatively, press the string that you'd
            like to tune.
          </li>
          <li>
            Tuning your guitar has never been so easy. Enjoy your well-tuned
            instrument!
          </li>
        </ol>
        <br />
        <p>
          To tune your <strong>acoustic guitar by ear:</strong>
        </p>
        <ol>
          <li>
            Press the string you’re trying to tune. The selected string will
            play every 2 seconds so you can concentrate on your instrument.
          </li>
          <li>You’re improving your musical ear. Good job!</li>
        </ol>
        <br />
        <p>
          <strong>Pro tip:</strong> Make sure to bookmark this page to find it
          easily next time!
        </p>
      </>
    ),
    es: (
      <>
        <p>
          Puedes afinar tu guitarra acústica con un micrófono o de oído. Afinar
          la guitarra <strong>automáticamente con un micrófono</strong> es más
          simple, rápido y es la opción que recomendamos. Sin embargo, afinar tu
          instrumento de oído mejorará tu oído musical en perspectiva, y puede
          ser una habilidad valiosa para los momentos cuando no estés online.
          <br />
          <br />
          Para afinar tu <strong> guitarra acústica automáticamente: </strong>
          <br />
        </p>
        <ol>
          <li>
            Pulsa el cambio de micrófono para afinar la guitarra{" "}
            automáticamente. Asegúrete de permitir tu página web usar el
            micrófono.
          </li>
          <li>
            Coloca tu instrumento cerca del micrófono para que el afinador pueda
            reconocer la cadena que estás tocando. Si Detectar la Cadena está
            activado el el afinador de guitarra acústica tratará identificar
            automáticamente la cadena que afinas. O bien, pulsa la cadena que
            quisieras afinar.
          </li>
          <li>
            Afinar tu guitarra nunca ha sido tan simple. Disfruta de tu
            instrumento bien afinado!
          </li>
        </ol>
        <br />
        <p>
          Para afinar tu <strong> guitarra acústica de oído: </strong>
        </p>
        <br />
        <ol>
          <li>
            Pulsa la cadena que intentas afinar. La cadena seleccionada tocará
            cada 2 segundos para que puedas concentrar en tu instrumento.
          </li>
          <li>Estás mejorando tu oído musical. ¡Buen trabajo!</li>
        </ol>
        <br />
        <p>
          <strong> Consejo profesional: </strong> asegúrate de marcar esta
          página para encontrarla fácilmente la próxima vez!
        </p>
      </>
    ),
    fr: (
      <>
        <p>
          Vous pouvez accorder votre guitare acoustique avec un microphone ou à
          l'oreille. L'accord automatique de la guitare{" "}
          <strong>avec un microphone</strong> est beaucoup plus facile et
          rapide, et c'est notre option recommandée. Cependant, l'accordage de
          votre instrument à l'oreille améliorera votre oreille musicale à long
          terme, et peut être une compétence précieuse à apprendre pour les
          moments où vous n'êtes pas en ligne.
          <br />
          <br />
          Pour accorder votre guitare acoustique{" "}
          <strong>automatiquement:</strong>
          <br />
        </p>
        <ol>
          <li>
            Appuyez sur le bouton Microphone pour accorder votre guitare
            automatiquement. Veillez à autoriser le site web à utiliser votre
            microphone.
          </li>
          <li>
            Rapprochez votre instrument de votre microphone pour que l'accordeur
            puisse reconnaître la corde que vous jouez. Si l'option{" "}
            <em>Auto-Corde</em> est activée, l'accordeur guitare acoustique{" "}
            essaiera d'identifier automatiquement la corde que vous accordez.
            Sinon, appuyez sur la corde que vous souhaitez accorder.
          </li>
          <li>
            Accorder votre guitare n'a jamais été aussi facile. Profitez de
            votre instrument bien accordé!
          </li>
        </ol>
        <br />
        <p>
          Pour accorder votre guitare acoustique <strong>à l'oreille:</strong>
        </p>
        <br />
        <ol>
          <li>
            Appuyez sur la corde que vous essayez d'accorder. La corde
            sélectionnée jouera toutes les 2 secondes pour que vous puissiez
            vous concentrer sur votre instrument.
          </li>
          <li>Vous améliorez ainsi votre oreille musicale. Bon travail!</li>
        </ol>
        <br />
        <p>
          <strong>Conseil du pro:</strong> Assurez-vous d'ajouter cette page à
          vos favoris pour la retrouver facilement la prochaine fois!
        </p>
      </>
    ),
    de: (
      <>
        <p>
          Sie können Ihre akustik Gitarre mit einem Mikrofon oder nach Gehör
          stimmen. Das automatische Stimmen der Gitarre{" "}
          <strong>mit einem Mikrofon</strong> ist viel einfacher, schneller und
          wird von uns empfohlen. Wenn Sie Ihr Instrument jedoch nach Gehör
          stimmen, wird sich Ihr musikalisches Gehör langfristig verbessern, und
          es kann eine wertvolle Fähigkeit sein, die Sie für die Momente lernen
          können, in denen Sie nicht online sind.
          <br />
          <br />
          So stimmen Sie Ihre akustik Gitarre <strong>automatisch:</strong>
          <br />
        </p>
        <ol>
          <li>
            Drücken Sie den Mikrophonschalter, um die Gitarre automatisch zu
            stimmen. Stellen Sie sicher, dass die Website die Verwendung Ihres
            Mikrofons zulässt.
          </li>
          <li>
            Bringen Sie Ihr Instrument nah an Ihr Mikrofon, damit das Stimmgerät
            die von Ihnen gespielte Saite erkennen kann. Wenn "Saite erkennen"
            eingeschaltet ist, wird das Stimmgerät akustik Gitarre versuchen,
            die Saite, die Sie gerade stimmen, automatisch zu erkennen.
            Alternativ können Sie auch die Saite drücken, die Sie stimmen
            möchten.
          </li>
          <li>
            Das Stimmen Ihrer Gitarre war noch nie so einfach. Genießen Sie Ihr
            gut gestimmtes Instrument!
          </li>
        </ol>
        <br />
        <p>
          So stimmen Sie Ihre akustik Gitarre <strong>nach Gehör:</strong>
        </p>
        <br />
        <ol>
          <li>
            Drücken Sie die Saite, die Sie zu stimmen versuchen. Die gewählte
            Saite wird alle 2 Sekunden gespielt, damit Sie sich auf Ihr
            Instrument konzentrieren können.
          </li>
          <li>Sie verbessern Ihr musikalisches Gehör. Gute Arbeit!</li>
        </ol>
        <br />
        <p>
          <strong>Profi-Tipp:</strong> Setzen Sie ein Lesezeichen auf diese
          Seite, um sie beim nächsten Mal leicht wiederzufinden!
        </p>
      </>
    ),
    zh: (
      <>
        <p>
          你可以使用麦克风或耳朵为你的木吉他调音。 使用
          <strong>麦克风自动调音</strong>更简单、更快，
          是我们推荐的选项。然而，通过耳朵调音可以在长期内
          提升你的音乐听觉，并且当你无法在线时，这也是一个 值得学习的宝贵技能。
          <br />
          <br />要<strong>自动调音你的木吉他：</strong>
          <br />
        </p>
        <ol>
          <li>按下麦克风开关以自动调音吉他。 请确保允许网站使用你的麦克风。</li>
          <li>
            将你的乐器靠近麦克风，让调音器识别你正在弹奏的弦。 如果
            <strong>检测弦</strong>功能开启，木吉他调音器
            将尝试自动识别你正在调音的弦。或者，按下你想调的弦。
          </li>
          <li>调音你的吉他从未如此简单。享受你调准的乐器吧！</li>
        </ol>
        <br />
        <p>
          要<strong>通过耳朵调音你的木吉他：</strong>
        </p>
        <ol>
          <li>按下你要调的弦。选定的弦每2秒播放一次， 让你专注于你的乐器。</li>
          <li>你正在提升你的音乐听觉。干得好！</li>
        </ol>
        <br />
        <p>
          <strong>专业提示：</strong>请确保将此页面添加书签， 以便下次轻松找到！
        </p>
      </>
    ),
    id: (
      <>
        <p>
          Kamu dapat menyetel gitar akustik kamu dengan mikrofon atau dengan
          telinga. Menyetel gitar{" "}
          <strong>secara otomatis dengan mikrofon</strong> lebih mudah, lebih
          cepat, dan merupakan opsi yang kami rekomendasikan. Namun, menyetel
          instrumen kamu dengan telinga akan meningkatkan pendengaran musik kamu
          dalam jangka panjang, dan dapat menjadi keterampilan berharga yang
          bisa kamu pelajari saat kamu tidak sedang online.
          <br />
          <br />
          Untuk menyetel gitar akustik kamu <strong>secara otomatis:</strong>
          <br />
        </p>
        <ol>
          <li>
            Tekan tombol Mikrofon untuk menyetel gitar secara otomatis. Pastikan
            untuk mengizinkan situs web menggunakan mikrofon kamu.
          </li>
          <li>
            Dekatkan instrumen kamu ke mikrofon agar penyetel dapat mengenali
            senar yang kamu mainkan. Jika <em>Deteksi Senar</em> diaktifkan,
            penyetel gitar akustik akan mencoba mengenali senar yang kamu setel
            secara otomatis. Atau, tekan senar yang ingin kamu setel.
          </li>
          <li>
            Menyetel gitar kamu tidak pernah semudah ini. Nikmati instrumen kamu
            yang sudah disetel dengan baik!
          </li>
        </ol>
        <br />
        <p>
          Untuk menyetel gitar akustik kamu <strong>dengan telinga:</strong>
        </p>
        <ol>
          <li>
            Tekan senar yang ingin kamu setel. Senar yang dipilih akan diputar
            setiap 2 detik agar kamu bisa fokus pada instrumen kamu.
          </li>
          <li>Kamu sedang meningkatkan pendengaran musik kamu. Kerja bagus!</li>
        </ol>
        <br />
        <p>
          <strong>Tip Pro:</strong> Pastikan untuk menandai halaman ini agar
          mudah ditemukan di lain waktu!
        </p>
      </>
    ),
    ko: (
      <>
        <p>
          당신은 마이크를 사용하거나 귀로 어쿠스틱 기타를 조율할 수 있습니다.
          <strong>마이크로 자동 조율</strong>하는 것이 훨씬 더 쉽고 빠르며,
          우리가 추천하는 옵션입니다. 하지만 귀로 악기를 조율하는 것은
          장기적으로 음악적 청력을 향상시키는 데 도움이 되며, 오프라인일 때 배울
          수 있는 소중한 기술이 될 수 있습니다.
          <br />
          <br />
          <strong>자동으로 어쿠스틱 기타를 조율하려면:</strong>
          <br />
        </p>
        <ol>
          <li>
            기타를 자동으로 조율하려면 마이크 버튼을 누르세요. 웹사이트가
            마이크를 사용할 수 있도록 허용해야 합니다.
          </li>
          <li>
            조율기가 연주하는 줄을 인식할 수 있도록 악기를 마이크 가까이
            가져가세요. <em>줄 감지</em> 기능이 켜져 있으면, 어쿠스틱 기타
            조율기는 조율 중인 줄을 자동으로 식별하려고 합니다. 또는 조율하려는
            줄을 누르세요.
          </li>
          <li>
            기타를 조율하는 것이 이보다 쉬울 수는 없습니다. 잘 조율된 악기를
            즐기세요!
          </li>
        </ol>
        <br />
        <p>
          <strong>귀로 어쿠스틱 기타를 조율하려면:</strong>
        </p>
        <ol>
          <li>
            조율하려는 줄을 누르세요. 선택한 줄이 2초마다 재생되어, 악기에
            집중할 수 있습니다.
          </li>
          <li>음악적 청력이 향상되고 있습니다. 잘하고 있어요!</li>
        </ol>
        <br />
        <p>
          <strong>프로 팁:</strong> 이 페이지를 즐겨찾기에 추가하여 다음에 쉽게
          찾을 수 있도록 하세요!
        </p>
      </>
    ),
    bg: (
      <>
        <p>
          Можете да настроите вашата акустична китара с микрофон или на ухо.
          Настройването на китарата <strong>автоматично с микрофон</strong> е
          много по-лесно, бързо и това е нашият препоръчителен вариант. Въпреки
          това, настройването на вашия инструмент на ухо ще подобри музикалния
          ви слух в дългосрочен план и може да бъде ценен умение, когато не сте
          онлайн.
          <br />
          <br />
          За да настроите <strong>акустичната китара автоматично:</strong>
          <br />
        </p>
        <ol>
          <li>
            Натиснете бутона за микрофона, за да настроите китарата автоматично.
            Уверете се, че позволявате на уебсайта да използва вашия микрофон.
          </li>
          <li>
            Приближете вашия инструмент до микрофона, за да може настройвачът да
            разпознае струната, която свирите. Ако е активирана функцията{" "}
            <em>Разпознаване на струни</em>, акустичният настройвач на китара ще
            се опита автоматично да идентифицира струната, която настройвате.
            Алтернативно, натиснете струната, която искате да настроите.
          </li>
          <li>
            Настройването на китарата ви никога не е било толкова лесно.
            Наслаждавайте се на добре настроения си инструмент!
          </li>
        </ol>
        <br />
        <p>
          За да настроите вашата <strong>акустична китара на ухо:</strong>
        </p>
        <ol>
          <li>
            Натиснете струната, която се опитвате да настроите. Избраната струна
            ще свири на всеки 2 секунди, за да можете да се концентрирате върху
            инструмента си.
          </li>
          <li>Подобрявате музикалния си слух. Добра работа!</li>
        </ol>
        <br />
        <p>
          <strong>Професионален съвет:</strong> Уверете се, че сте маркирали
          тази страница, за да я намерите лесно следващия път!
        </p>
      </>
    ),
    jp: (
      <>
        <p>
          あなたのアコースティックギターは、マイクを使うか耳でチューニングできます。
          <strong>マイクで自動チューニング</strong>する方が簡単で早く、
          私たちが推奨するオプションです。しかし、耳で楽器をチューニングすることは、
          長期的にあなたの音感を向上させ、オンラインでない時でも役立つスキルです。
          <br />
          <br />
          <strong>アコースティックギターを自動でチューニ ングするには：</strong>
          <br />
        </p>
        <ol>
          <li>
            マイクのスイッチを押してギターを自動でチューニングしてください。
            サイトがマイクを使用することを許可するようにしてください。
          </li>
          <li>
            あなたの楽器をマイクに近づけて、チューナーが弾いている弦を認識できるようにします。
            <em>弦を検出</em>
            がオンになっている場合、アコースティックギターチューナーは
            調整中の弦を自動的に識別しようとします。または、チューニングしたい弦を押してください。
          </li>
          <li>
            ギターのチューニングがこれまでになく簡単です。
            調整された楽器を楽しんでください！
          </li>
        </ol>
        <br />
        <p>
          <strong>耳でアコースティックギターをチューニングするには：</strong>
        </p>
        <ol>
          <li>
            チューニングしたい弦を押してください。選択した弦は2秒ごとに鳴り、
            楽器に集中することができます。
          </li>
          <li>あなたの音感が向上しています。よくやりました！</li>
        </ol>
        <br />
        <p>
          <strong>プロのヒント：</strong>
          次回簡単に見つけられるよう、このページをブックマークしてください！
        </p>
      </>
    ),
    pt: (
      <>
        <p>
          Você pode afinar seu violão com um microfone ou de ouvido. Afinar o
          violão <strong>automaticamente com um microfone</strong> é muito mais
          fácil, rápido, e é a nossa opção recomendada. No entanto, afinar seu
          instrumento de ouvido vai melhorar sua percepção musical a longo
          prazo, e pode ser uma habilidade valiosa para aprender nos momentos em
          que você não estiver online.
          <br />
          <br />
          Para afinar seu <strong>violão automaticamente:</strong>
          <br />
        </p>
        <ol>
          <li>
            Pressione o botão do microfone para afinar o violão automaticamente.
            Certifique-se de permitir que o site utilize o seu microfone.
          </li>
          <li>
            Aproxime o instrumento do microfone para que o afinador reconheça a
            corda que você está tocando. Se o <em>Detecção de Corda</em> estiver
            ativado, o afinador de violão tentará identificar automaticamente a
            corda que você está afinando. Alternativamente, pressione a corda
            que você quer afinar.
          </li>
          <li>
            Afinar seu violão nunca foi tão fácil. Aproveite seu instrumento bem
            afinado!
          </li>
        </ol>
        <br />
        <p>
          Para afinar seu <strong>violão de ouvido:</strong>
        </p>
        <ol>
          <li>
            Pressione a corda que você está tentando afinar. A corda selecionada
            tocará a cada 2 segundos para que você possa se concentrar no seu
            instrumento.
          </li>
          <li>Você está melhorando sua percepção musical. Bom trabalho!</li>
        </ol>
        <br />
        <p>
          <strong>Dica profissional:</strong> Certifique-se de marcar esta
          página para encontrá-la facilmente na próxima vez!
        </p>
      </>
    ),
    tr: (
      <>
        <p>
          Akustik gitarınızı bir mikrofonla veya kulaktan akort edebilirsiniz.
          Gitarı <strong>mikrofonla otomatik olarak akort etmek</strong> çok
          daha kolay, hızlıdır ve tavsiye ettiğimiz seçenektir. Bununla
          birlikte, enstrümanınızı kulaktan akort etmek, müzikal duyumunuzu uzun
          vadede geliştirir ve çevrimdışı olduğunuzda öğrenebileceğiniz değerli
          bir beceri olabilir.
          <br />
          <br />
          Akustik gitarınızı <strong>otomatik olarak akort etmek için:</strong>
          <br />
        </p>
        <ol>
          <li>
            Gitarı otomatik olarak akort etmek için Mikrofon düğmesine basın.
            Web sitesinin mikrofonunuzu kullanmasına izin verdiğinizden emin
            olun.
          </li>
          <li>
            Enstrümanınızı mikrofonunuza yaklaştırın, böylece akort cihazı
            çaldığınız teli tanıyabilir. <em>Tel Tespiti</em> açık ise, akustik
            gitar akort cihazı, akort ettiğiniz teli otomatik olarak tanımaya
            çalışacaktır. Alternatif olarak, akort etmek istediğiniz tele basın.
          </li>
          <li>
            Gitarınızı akort etmek hiç bu kadar kolay olmamıştı. İyi akort
            edilmiş enstrümanınızın keyfini çıkarın!
          </li>
        </ol>
        <br />
        <p>
          Akustik gitarınızı <strong>kulaktan akort etmek için:</strong>
        </p>
        <ol>
          <li>
            Akort etmeye çalıştığınız tele basın. Seçilen tel her 2 saniyede bir
            çalınacak, böylece enstrümanınıza odaklanabilirsiniz.
          </li>
          <li>Müzikal duyumunuzu geliştiriyorsunuz. İyi iş!</li>
        </ol>
        <br />
        <p>
          <strong>Profesyonel ipucu:</strong> Bir dahaki sefere kolayca bulmak
          için bu sayfayı yer imlerine eklediğinizden emin olun!
        </p>
      </>
    ),
    vi: (
      <>
        <p>
          Bạn có thể lên dây đàn guitar acoustic của mình bằng micro hoặc bằng
          tai. Lên dây đàn <strong>tự động bằng micro</strong> dễ dàng hơn
          nhiều, nhanh chóng hơn và là lựa chọn mà chúng tôi khuyên dùng. Tuy
          nhiên, lên dây đàn của bạn bằng tai sẽ cải thiện tai nghe nhạc của bạn
          trong dài hạn, và có thể là một kỹ năng quý giá để học hỏi khi bạn
          không trực tuyến.
          <br />
          <br />
          Để lên dây <strong>đàn guitar acoustic tự động:</strong>
          <br />
        </p>
        <ol>
          <li>
            Nhấn vào nút Micro để lên dây đàn tự động. Hãy đảm bảo rằng trang
            web được phép sử dụng micro của bạn.
          </li>
          <li>
            Đưa nhạc cụ của bạn gần micro để bộ lên dây nhận diện dây đàn bạn
            đang đánh. Nếu <em>Phát Hiện Dây</em> được bật, bộ lên dây guitar
            acoustic sẽ cố gắng tự động xác định dây mà bạn đang lên. Hoặc, nhấn
            vào dây mà bạn muốn lên.
          </li>
          <li>
            Lên dây đàn của bạn chưa bao giờ dễ dàng đến thế. Hãy tận hưởng cây
            đàn của bạn được lên dây chuẩn!
          </li>
        </ol>
        <br />
        <p>
          Để lên dây <strong>đàn guitar acoustic bằng tai:</strong>
        </p>
        <ol>
          <li>
            Nhấn vào dây mà bạn đang cố gắng lên dây. Dây được chọn sẽ phát mỗi
            2 giây để bạn có thể tập trung vào nhạc cụ của mình.
          </li>
          <li>Bạn đang cải thiện khả năng nghe nhạc của mình. Làm tốt lắm!</li>
        </ol>
        <br />
        <p>
          <strong>Mẹo chuyên nghiệp:</strong> Hãy đánh dấu trang này để dễ dàng
          tìm thấy vào lần sau!
        </p>
      </>
    ),
    tw: (
      <>
        <p>
          您可以使用麥克風或耳朵為您的木吉他調音。 使用
          <strong>麥克風自動調音</strong>更簡單、更快捷，
          是我們推薦的選項。然而，通過耳朵調音可以長期提升您的音樂聽覺，
          並且當您無法上網時，這也是一項值得學習的寶貴技能。
          <br />
          <br />要<strong>自動調音您的木吉他：</strong>
          <br />
        </p>
        <ol>
          <li>按下麥克風開關自動調音吉他。請確保允許網站使用您的麥克風。</li>
          <li>
            將您的樂器靠近麥克風，以便調音器識別您正在彈奏的弦。 如果
            <em>檢測弦</em>功能開啟，木吉他調音器將嘗試自動識別您正在調音的弦。
            或者，按下您要調的弦。
          </li>
          <li>調音您的吉他從未如此簡 單。享受調好音的樂器吧！</li>
        </ol>
        <br />
        <p>
          要<strong>通過耳朵調音您的木吉他：</strong>
        </p>
        <ol>
          <li>
            按下您想要調音的弦。選定的弦每2秒播放一次， 讓您專注於您的樂器。
          </li>
          <li>您正在提升您的音樂聽覺。幹得好！</li>
        </ol>
        <br />
        <p>
          <strong>專業提示：</strong>請確保將此頁面加為書籤， 以便下次輕鬆找到！
        </p>
      </>
    ),
    pl: (
      <>
        <p>
          Możesz nastroić swoją gitarę akustyczną za pomocą mikrofonu lub na
          słuch. Strojenie gitary{" "}
          <strong>automatycznie za pomocą mikrofonu</strong> jest znacznie
          łatwiejsze, szybsze i jest to opcja, którą polecamy. Jednakże,
          strojenie instrumentu na słuch poprawi twoją muzyczną słuchowość na
          dłuższą metę i może być cenną umiejętnością do nauki na momenty, kiedy
          nie jesteś online.
          <br />
          <br />
          Aby <strong>automatycznie nastroić gitarę akustyczną:</strong>
          <br />
        </p>
        <ol>
          <li>
            Naciśnij przycisk Mikrofon, aby automatycznie nastroić gitarę.
            Upewnij się, że strona internetowa może korzystać z twojego
            mikrofonu.
          </li>
          <li>
            Zbliż instrument do mikrofonu, aby stroik mógł rozpoznać strunę,
            którą grasz. Jeśli <em>Wykrywanie Struny</em> jest włączone, stroik
            do gitary akustycznej spróbuje automatycznie rozpoznać strojoną
            strunę. Alternatywnie, naciśnij strunę, którą chcesz nastroić.
          </li>
          <li>
            Strojenie gitary nigdy nie było tak proste. Ciesz się dobrze
            nastrojonym instrumentem!
          </li>
        </ol>
        <br />
        <p>
          Aby <strong>nastroić gitarę akustyczną na słuch:</strong>
        </p>
        <ol>
          <li>
            Naciśnij strunę, którą próbujesz nastroić. Wybrana struna będzie
            odtwarzana co 2 sekundy, abyś mógł się skupić na swoim instrumencie.
          </li>
          <li>Poprawiasz swój muzyczny słuch. Dobra robota!</li>
        </ol>
        <br />
        <p>
          <strong>Wskazówka:</strong> Upewnij się, że dodałeś tę stronę do
          zakładek, aby łatwo ją znaleźć następnym razem!
        </p>
      </>
    ),
    it: (
      <>
        <p>
          Puoi accordare la tua chitarra acustica con un microfono o a orecchio.
          Accordare la chitarra{" "}
          <strong>automaticamente con un microfono</strong> è molto più facile,
          veloce, ed è l'opzione che consigliamo. Tuttavia, accordare il tuo
          strumento a orecchio migliorerà il tuo orecchio musicale a lungo
          termine e può essere una competenza preziosa da apprendere quando non
          sei online.
          <br />
          <br />
          Per{" "}
          <strong>accordare automaticamente la tua chitarra acustica:</strong>
          <br />
        </p>
        <ol>
          <li>
            Premi l'interruttore del Microfono per accordare la chitarra
            automaticamente. Assicurati di consentire al sito web di utilizzare
            il tuo microfono.
          </li>
          <li>
            Avvicina il tuo strumento al microfono affinché l'accordatore possa
            riconoscere la corda che stai suonando. Se{" "}
            <em>Rilevamento Corda</em> è attivo, l'accordatore per chitarra
            acustica cercherà di identificare automaticamente la corda che stai
            accordando. In alternativa, premi la corda che desideri accordare.
          </li>
          <li>
            Accordare la tua chitarra non è mai stato così facile. Goditi il tuo
            strumento ben accordato!
          </li>
        </ol>
        <br />
        <p>
          Per <strong>accordare la tua chitarra acustica a orecchio:</strong>
        </p>
        <ol>
          <li>
            Premi la corda che stai cercando di accordare. La corda selezionata
            suonerà ogni 2 secondi per permetterti di concentrarti sul tuo
            strumento.
          </li>
          <li>Stai migliorando il tuo orecchio musicale. Ottimo lavoro!</li>
        </ol>
        <br />
        <p>
          <strong>Consiglio professionale:</strong> Assicurati di aggiungere
          questa pagina ai preferiti per trovarla facilmente la prossima volta!
        </p>
      </>
    ),
    sv: (
      <>
        <p>
          Du kan stämma din akustiska gitarr med en mikrofon eller på gehör. Att
          stämma gitarren <strong>automatiskt med en mikrofon</strong> är mycket
          enklare, snabbare och är vårt rekommenderade alternativ. Men att
          stämma ditt instrument på gehör kommer att förbättra ditt musiköra på
          lång sikt, och kan vara en värdefull färdighet att lära sig för
          tillfällen när du inte är online.
          <br />
          <br />
          För att <strong>automatiskt stämma din akustiska gitarr:</strong>
          <br />
        </p>
        <ol>
          <li>
            Tryck på Mikrofonknappen för att automatiskt stämma gitarren. Se
            till att tillåta webbplatsen att använda din mikrofon.
          </li>
          <li>
            För din gitarr nära mikrofonen så att stämapparaten kan känna igen
            strängen du spelar. Om <em>Strängdetektion</em> är aktiverad, kommer
            den akustiska gitarrstämapparaten att försöka identifiera strängen
            du stämmer automatiskt. Alternativt kan du trycka på den sträng du
            vill stämma.
          </li>
          <li>
            Att stämma din gitarr har aldrig varit så enkelt. Njut av ditt
            välstämda instrument!
          </li>
        </ol>
        <br />
        <p>
          För att <strong>stämma din akustiska gitarr på gehör:</strong>
        </p>
        <ol>
          <li>
            Tryck på den sträng du försöker stämma. Den valda strängen kommer
            att spelas var 2:a sekund så att du kan koncentrera dig på ditt
            instrument.
          </li>
          <li>Du förbättrar ditt musiköra. Bra jobbat!</li>
        </ol>
        <br />
        <p>
          <strong>Proffstips:</strong> Se till att bokmärka den här sidan så att
          du enkelt hittar den nästa gång!
        </p>
      </>
    ),
    fi: (
      <>
        <p>
          Voit virittää akustisen kitarasi mikrofonilla tai korvakuulolta.
          Kitaran <strong>automaattinen viritys mikrofonilla</strong> on paljon
          helpompaa, nopeampaa, ja se on suositeltava vaihtoehto. Kuitenkin,
          instrumentin virittäminen korvakuulolta parantaa musiikillista
          kuuloasi pitkällä aikavälillä, ja se voi olla arvokas taito oppia
          niille hetkille, kun et ole verkossa.
          <br />
          <br />
          Viritä <strong>akustinen kitarasi automaattisesti:</strong>
          <br />
        </p>
        <ol>
          <li>
            Paina Mikrofonikytkintä virittääksesi kitaran automaattisesti.
            Varmista, että sivusto saa käyttää mikrofoniasi.
          </li>
          <li>
            Vie soittimesi lähelle mikrofonia, jotta viritin tunnistaa
            soittamasi kielen. Jos <em>Kielen tunnistus</em> on päällä,
            akustisen kitaran viritin yrittää automaattisesti tunnistaa
            virittämäsi kielen. Vaihtoehtoisesti paina sitä kieltä, jonka haluat
            virittää.
          </li>
          <li>
            Kitarasi virittäminen ei ole koskaan ollut näin helppoa. Nauti hyvin
            viritetystä soittimestasi!
          </li>
        </ol>
        <br />
        <p>
          Viritä <strong>akustinen kitarasi korvakuulolta:</strong>
        </p>
        <ol>
          <li>
            Paina kieltä, jota yrität virittää. Valittu kieli soi joka toinen
            sekunti, jotta voit keskittyä soittimeesi.
          </li>
          <li>Parannat musiikillista kuuloasi. Hienoa työtä!</li>
        </ol>
        <br />
        <p>
          <strong>Ammattilaisen vinkki:</strong> Varmista, että lisäät tämän
          sivun kirjanmerkkeihin, jotta löydät sen helposti seuraavalla
          kerralla!
        </p>
      </>
    ),
    hi: (
      <>
        <p>
          आप अपनी ध्वनिक गिटार को माइक्रोफोन या सुनकर ट्यून कर सकते हैं। गिटार
          को <strong>माइक्रोफोन से स्वचालित रूप से ट्यून करना</strong>
          बहुत आसान, तेज़ है, और यह हमारा अनुशंसित विकल्प है। हालांकि, अपने
          वाद्ययंत्र को सुनकर ट्यून करना आपके संगीत कान को लंबे समय में सुधार
          देगा, और यह आपके ऑफलाइन होने पर सीखने के लिए एक मूल्यवान कौशल हो सकता
          है।
          <br />
          <br />
          अपनी{" "}
          <strong>ध्वनिक गिटार को स्वचालित रूप से ट्यून करने के लिए:</strong>
          <br />
        </p>
        <ol>
          <li>
            गिटार को स्वचालित रूप से ट्यून करने के लिए माइक्रोफोन स्विच दबाएं।
            सुनिश्चित करें कि आप वेबसाइट को अपने माइक्रोफोन का उपयोग करने की
            अनुमति दें।
          </li>
          <li>
            अपने वाद्ययंत्र को माइक्रोफोन के करीब लाएं ताकि ट्यूनर आपके द्वारा
            बजाए जा रहे तार को पहचान सके। यदि <em>तार पहचान</em> चालू है, तो
            ध्वनिक गिटार ट्यूनर स्वतः आपके द्वारा ट्यून किए जा रहे तार को
            पहचानने का प्रयास करेगा। वैकल्पिक रूप से, उस तार को दबाएं जिसे आप
            ट्यून करना चाहते हैं।
          </li>
          <li>
            आपकी गिटार को ट्यून करना कभी इतना आसान नहीं था। अपने अच्छी तरह से
            ट्यून किए गए वाद्ययंत्र का आनंद लें!
          </li>
        </ol>
        <br />
        <p>
          अपनी <strong>ध्वनिक गिटार को सुनकर ट्यून करने के लिए:</strong>
        </p>
        <ol>
          <li>
            उस तार को दबाएं जिसे आप ट्यून करने का प्रयास कर रहे हैं। चयनित तार
            हर 2 सेकंड में बजेगा ताकि आप अपने वाद्ययंत्र पर ध्यान केंद्रित कर
            सकें।
          </li>
          <li>आप अपने संगीत कान को सुधार रहे हैं। अच्छा काम!</li>
        </ol>
        <br />
        <p>
          <strong>प्रो टिप:</strong> इस पेज को बुकमार्क करना सुनिश्चित करें ताकि
          अगली बार इसे आसानी से ढूंढ सकें!
        </p>
      </>
    ),
  },
};

export default acousticGuitarTuning;
