export const defaultLocale = 'en'

export const languageNames = {
  en: '🇺🇸 English',
  es: '🇪🇸 Español',
  fr: '🇫🇷 Français',
  de: '🇩🇪 Deutsch',
  zh: '🇨🇳 中文',
  id: '🇮🇩 Bahasa Indonesia',
  ko: '🇰🇷 한국어',
  bg: '🇧🇬 Български',
  jp: '🇯🇵 日本語',
  pt: '🇵🇹 Português',
  tr: '🇹🇷 Türkçe',
  vi: '🇻🇳 Tiếng Việt',
  tw: '🇹🇼 繁體中文',
  pl: '🇵🇱 Polski',
  it: '🇮🇹 Italiano',
  sv: '🇸🇪 Svenska',
  fi: '🇫🇮 Suomi',
  hi: '🇮🇳 हिन्दी',
}

export const locales = Object.keys(languageNames)
export const nonEnglishLocales = locales.filter(locale => locale !== 'en')

export function isLocale(tested) {
  return locales.some(locale => locale === tested)
}