import React from 'react'
import { useRouter } from 'next/dist/client/router'
import { locales, languageNames } from '../translations/config'
import { LocaleContext } from '../translations/context'
import styles from './LocaleSwitcher.module.scss'

function getLinkToCurrentPageWithLocale(locale, router) {
  let pathnameWithoutLocale = router.query.page ? ('/' + router.query.page) : router.pathname.split('/[lang]').pop()
  if (pathnameWithoutLocale === '/') pathnameWithoutLocale = '' // we don't want trailing slashes
  if (locale === 'en')
    return pathnameWithoutLocale ? pathnameWithoutLocale : '/'

  return `/${locale}${pathnameWithoutLocale}`
}

const LocaleSwitcher = () => {
  const router = useRouter()
  const { locale } = React.useContext(LocaleContext)

  return (
    <ul className={styles.options}>
      {locales.map((locale_i) => (
        <li
          className={locale === locale_i ? styles.activeOption : styles.option}
          key={locale_i}
        >
          <a href={getLinkToCurrentPageWithLocale(locale_i, router)}>
            {languageNames[locale_i]}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default LocaleSwitcher
