import LocaleSwitcher from './LocaleSwitcher'
import footerStyles from './Footer.module.scss'
import useTranslation from '../translations/useTranslation'

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.copyrightGroup}>
        <h3>
          <strong>Guitar</strong>-Tuner.org
        </h3>{' '}
        <span className={footerStyles.copyright}>© 2024</span>
      </div>
      <br />
      <div className={footerStyles.localeSwitcher}>
        <LocaleSwitcher />
      </div>
      <br />
      <div>
        <p className={footerStyles.contactUs}>
          <a
            className={footerStyles.contactUs}
            href='/privacy-policy'
          >
            Privacy Policy
          </a>
          .&nbsp;{' '}
          {t('contactUsAt')}{' '}
          <a href='mailto:team@guitar-tuner.org'>team@guitar-tuner.org</a>
          <br />
          <br />
        </p>
      </div>

      <script src='/aubio.js'></script>
    </footer>
  )
}
