import { useContext } from 'react'
import { LocaleContext } from './context'
import strings from './strings'
import { defaultLocale } from './config'

export default function useTranslation(localeArg) {
  let { locale } = useContext(LocaleContext)
  locale = localeArg || locale

  function t(key, props) {
    if (!strings[key][locale]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`)
    }

    if (typeof strings[key][locale] === 'function') {
      return strings[key][locale](props)
    } else {
      return strings[key][locale] || strings[key][defaultLocale] || ''
    }
  }

  return {
    t,
    locale,
  }
}
