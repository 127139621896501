const home = {
  howToUseText: {
    en: (
      <>
        <p>
          You can tune your guitar with a microphone or by ear. Tuning the
          guitar <strong>automatically with a microphone</strong> is much
          easier, faster, and is our recommended option. However, tuning your
          instrument by ear will improve your musical ear in the long term, and
          can be a valuable skill to learn for the moments when you are not
          online.
          <br />
          <br />
          To tune your <strong>guitar automatically:</strong>
          <br />
        </p>
        <ol>
          <li>
            Press the Microphone switch to tune the guitar automatically. Make
            sure to allow the website to use your microphone.
          </li>
          <li>
            Get your instrument close to your microphone to let the tuner
            recognize the string you’re playing. If <em>Detect String</em> is
            on, the guitar tuner will try to automatically identify the string
            you’re tuning. Alternatively, press the string that you'd like to
            tune.
          </li>
          <li>
            Tuning your guitar has never been so easy. Enjoy your well-tuned
            instrument!
          </li>
        </ol>
        <br />
        <p>
          To tune your <strong>guitar by ear:</strong>
        </p>
        <ol>
          <li>
            Press the string you’re trying to tune. The selected string will
            play every 2 seconds so you can concentrate on your instrument.
          </li>
          <li>You’re improving your musical ear. Good job!</li>
        </ol>
        <br />
        <p>
          <strong>Pro tip:</strong> Make sure to bookmark this page to find it
          easily next time!
        </p>
      </>
    ),
    es: (
      <>
        <p>
          Puedes afinar tu guitarra con un micrófono o de oído. Afinar la
          guitarra <strong>automáticamente con un micrófono</strong> es más
          simple, rápido y es la opción que recomendamos. Sin embargo, afinar tu
          instrumento de oído mejorará tu oído musical en perspectiva, y puede
          ser una habilidad valiosa para los momentos cuando no estés online.
          <br />
          <br />
          Para afinar tu <strong> guitarra automáticamente: </strong>
          <br />
        </p>
        <ol>
          <li>
            Pulsa el cambio de micrófono para afinar la guitarra{" "}
            automáticamente. Asegúrete de permitir tu página web usar el
            micrófono.
          </li>
          <li>
            Coloca tu instrumento cerca del micrófono para que el afinador pueda
            reconocer la cadena que estás tocando. Si Detectar la Cadena está
            activado el el afinador de guitarra tratará identificar
            automáticamente la cadena que afinas. O bien, pulsa la cadena que
            quisieras afinar.
          </li>
          <li>
            Afinar tu guitarra nunca ha sido tan simple. Disfruta de tu
            instrumento bien afinado!
          </li>
        </ol>
        <br />
        <p>
          Para afinar tu <strong> guitarra de oído: </strong>
        </p>
        <br />
        <ol>
          <li>
            Pulsa la cadena que intentas afinar. La cadena seleccionada tocará
            cada 2 segundos para que puedas concentrar en tu instrumento.
          </li>
          <li>Estás mejorando tu oído musical. ¡Buen trabajo!</li>
        </ol>
        <br />
        <p>
          <strong> Consejo profesional: </strong> asegúrate de marcar esta
          página para encontrarla fácilmente la próxima vez!
        </p>
      </>
    ),
    fr: (
      <>
        <p>
          Vous pouvez accorder votre guitare avec un microphone ou à l'oreille.
          L'accord automatique de la guitare <strong>avec un microphone</strong>{" "}
          est beaucoup plus facile et rapide, et c'est notre option recommandée.
          Cependant, l'accordage de votre instrument à l'oreille améliorera
          votre oreille musicale à long terme, et peut être une compétence
          précieuse à apprendre pour les moments où vous n'êtes pas en ligne.
          <br />
          <br />
          Pour accorder votre guitare <strong>automatiquement:</strong>
          <br />
        </p>
        <ol>
          <li>
            Appuyez sur le bouton Microphone pour accorder votre guitare
            automatiquement. Veillez à autoriser le site web à utiliser votre
            microphone.
          </li>
          <li>
            Rapprochez votre instrument de votre microphone pour que l'accordeur
            puisse reconnaître la corde que vous jouez. Si l'option{" "}
            <em>Auto-Corde</em> est activée, l'accordeur guitare essaiera
            d'identifier automatiquement la corde que vous accordez. Sinon,
            appuyez sur la corde que vous souhaitez accorder.
          </li>
          <li>
            Accorder votre guitare n'a jamais été aussi facile. Profitez de
            votre instrument bien accordé!
          </li>
        </ol>
        <br />
        <p>
          Pour accorder votre guitare <strong>à l'oreille:</strong>
        </p>
        <br />
        <ol>
          <li>
            Appuyez sur la corde que vous essayez d'accorder. La corde
            sélectionnée jouera toutes les 2 secondes pour que vous puissiez
            vous concentrer sur votre instrument.
          </li>
          <li>Vous améliorez ainsi votre oreille musicale. Bon travail!</li>
        </ol>
        <br />
        <p>
          <strong>Conseil du pro:</strong> Assurez-vous d'ajouter cette page à
          vos favoris pour la retrouver facilement la prochaine fois!
        </p>
      </>
    ),
    de: (
      <>
        <p>
          Sie können Ihre Gitarre mit einem Mikrofon oder nach Gehör stimmen.
          Das automatische Stimmen der Gitarre{" "}
          <strong>mit einem Mikrofon</strong> ist viel einfacher, schneller und
          wird von uns empfohlen. Wenn Sie Ihr Instrument jedoch nach Gehör
          stimmen, wird sich Ihr musikalisches Gehör langfristig verbessern, und
          es kann eine wertvolle Fähigkeit sein, die Sie für die Momente lernen
          können, in denen Sie nicht online sind.
          <br />
          <br />
          So stimmen Sie Ihre Gitarre <strong>automatisch:</strong>
          <br />
        </p>
        <ol>
          <li>
            Drücken Sie den Mikrophonschalter, um die Gitarre automatisch zu
            stimmen. Stellen Sie sicher, dass die Website die Verwendung Ihres
            Mikrofons zulässt.
          </li>
          <li>
            Bringen Sie Ihr Instrument nah an Ihr Mikrofon, damit das Stimmgerät
            die von Ihnen gespielte Saite erkennen kann. Wenn "Saite erkennen"
            eingeschaltet ist, wird das Stimmgerät Gitarre versuchen, die Saite,
            die Sie gerade stimmen, automatisch zu erkennen. Alternativ können
            Sie auch die Saite drücken, die Sie stimmen möchten.
          </li>
          <li>
            Das Stimmen Ihrer Gitarre war noch nie so einfach. Genießen Sie Ihr
            gut gestimmtes Instrument!
          </li>
        </ol>
        <br />
        <p>
          So stimmen Sie Ihre Gitarre <strong>nach Gehör:</strong>
        </p>
        <br />
        <ol>
          <li>
            Drücken Sie die Saite, die Sie zu stimmen versuchen. Die gewählte
            Saite wird alle 2 Sekunden gespielt, damit Sie sich auf Ihr
            Instrument konzentrieren können.
          </li>
          <li>Sie verbessern Ihr musikalisches Gehör. Gute Arbeit!</li>
        </ol>
        <br />
        <p>
          <strong>Profi-Tipp:</strong> Setzen Sie ein Lesezeichen auf diese
          Seite, um sie beim nächsten Mal leicht wiederzufinden!
        </p>
      </>
    ),
    zh: (
      <>
        <p>
          你可以用麦克风或耳朵来调你的吉他 。使用麦克风自动调谐吉他
          更容易、更快，是我们推荐的选择。然而，从长远来看，用耳朵调节乐器会提高你的音乐听觉，而且在你不在线的时候也是一项值得学习的技能。
          <br />
          <br />要<strong>自动调谐你的吉他</strong>：
          <br />
        </p>
        <ol>
          <li>按麦克风开关可自动调谐吉他 。确保允许网站使用您的麦克风。</li>
          <li>
            把你的乐器拿到靠近你的麦克风，让调谐器识别你正在演奏的弦乐。如果检测弦乐处于启用状态，
            吉他 调谐器将尝试自动识别正在调谐的弦乐。或者，按你要调谐的弦乐。
          </li>
          <li>吉他调音从未如此容易。享受你调好的乐器！</li>
        </ol>
        <br />
        <p>
          用<strong>耳朵调吉他:</strong>
        </p>
        <br />
        <ol>
          <li>
            按你要调的琴弦。选定的琴弦每2秒演奏一次，这样你就可以集中精力在乐器上了。
          </li>
          <li>你在提高你的音乐听觉。干得好！</li>
        </ol>
        <br />
        <p>
          <strong>专业提示：</strong>请务必将此页添加书签，以便下次轻松找到它！
        </p>
      </>
    ),
    id: (
      <>
        <p>
          Anda dapat menyetel gitar Anda dengan mikrofon atau secara manual.
          Menyetel gitar
          <strong>secara otomatis dengan mikrofon</strong> jauh lebih mudah,
          lebih cepat, dan merupakan opsi yang kami rekomendasikan. Namun,
          menyetel instrumen Anda secara manual akan meningkatkan pendengaran
          musik Anda dalam jangka panjang, dan dapat menjadi keterampilan
          berharga yang bisa dipelajari untuk saat-saat ketika Anda tidak
          online.
          <br />
          <br />
          Untuk menyetel <strong>gitar Anda secara otomatis:</strong>
          <br />
        </p>
        <ol>
          <li>
            Tekan sakelar Mikrofon untuk menyetel gitar Anda secara otomatis.
            Pastikan untuk mengizinkan situs web menggunakan mikrofon Anda.
          </li>
          <li>
            Dekatkan instrumen Anda ke mikrofon agar tuner dapat mengenali senar
            yang Anda mainkan. Jika <em>Deteksi Senar</em> diaktifkan, tuner
            gitar akan mencoba untuk mengenali senar yang Anda setel secara
            otomatis. Sebagai alternatif, tekan senar yang ingin Anda setel.
          </li>
          <li>
            Menyetel gitar Anda belum pernah semudah ini. Nikmati instrumen Anda
            yang sudah disetel dengan baik!
          </li>
        </ol>
        <br />
        <p>
          Untuk menyetel <strong>gitar Anda secara manual:</strong>
        </p>
        <ol>
          <li>
            Tekan senar yang ingin Anda setel. Senar yang dipilih akan berbunyi
            setiap 2 detik sehingga Anda bisa berkonsentrasi pada instrumen
            Anda.
          </li>
          <li>Anda sedang meningkatkan pendengaran musik Anda. Bagus!</li>
        </ol>
        <br />
        <p>
          <strong>Tip profesional:</strong> Pastikan untuk menandai halaman ini
          agar mudah ditemukan lagi lain kali!
        </p>
      </>
    ),
    ko: (
      <>
        <p>
          기타를 마이크로 또는 귀로 조율할 수 있습니다. 기타를
          <strong>마이크로 자동 조율</strong>하는 것이 훨씬 쉽고 빠르며, 저희가
          추천하는 방법입니다. 하지만 귀로 조율하는 것은 장기적으로 음악적
          청력을 향상시키며, 오프라인일 때도 유용한 기술을 배우는 데 도움이 될
          수 있습니다.
          <br />
          <br />
          기타를 <strong>자동으로 조율하려면:</strong>
          <br />
        </p>
        <ol>
          <li>
            마이크 스위치를 눌러 기타를 자동으로 조율하세요. 웹사이트가 마이크
            사용을 허용했는지 확인하세요.
          </li>
          <li>
            마이크가 소리를 인식할 수 있도록 악기를 마이크 가까이에 두세요.{" "}
            <em>스트링 감지</em>가 켜져 있다면, 기타 조율기가 자동으로 조율 중인
            줄을 인식하려고 할 것입니다. 아니면, 조율하려는 줄을 눌러 주세요.
          </li>
          <li>
            기타 조율이 이보다 더 쉬울 수 없습니다. 잘 조율된 악기를 즐기세요!
          </li>
        </ol>
        <br />
        <p>
          기타를 <strong>귀로 조율하려면:</strong>
        </p>
        <ol>
          <li>
            조율하려는 줄을 눌러 주세요. 선택된 줄은 2초마다 연주되어 악기에
            집중할 수 있습니다.
          </li>
          <li>음악적 청력이 향상되고 있습니다. 잘하고 있어요!</li>
        </ol>
        <br />
        <p>
          <strong>전문가 팁:</strong> 다음에 쉽게 찾을 수 있도록 이 페이지를
          북마크하세요!
        </p>
      </>
    ),
    bg: (
      <>
        <p>
          Можете да настроите китарата си с микрофон или на ухо. Настройването
          на китарата
          <strong>автоматично с микрофон</strong> е много по-лесно, по-бързо и е
          нашата препоръчана опция. Въпреки това, настройването на вашия
          инструмент на ухо ще подобри музикалния ви слух в дългосрочен план и
          може да бъде ценна умение, което да научите за моментите, когато не
          сте онлайн.
          <br />
          <br />
          За да настроите китарата си <strong>автоматично:</strong>
          <br />
        </p>
        <ol>
          <li>
            Натиснете превключвателя за микрофон, за да настроите китарата
            автоматично. Уверете се, че сте позволили на уебсайта да използва
            микрофона ви.
          </li>
          <li>
            Поставете инструмента си близо до микрофона, за да позволите на
            тунера да разпознае струната, която свирите. Ако{" "}
            <em>Автоматично разпознаване на струни</em> е включено, тунерът за
            китара ще се опита автоматично да идентифицира струната, която
            настройвате. Като алтернатива, натиснете струната, която искате да
            настроите.
          </li>
          <li>
            Настройването на китарата никога не е било толкова лесно. Насладете
            се на добре настроения си инструмент!
          </li>
        </ol>
        <br />
        <p>
          За да настроите китарата си <strong>на ухо:</strong>
        </p>
        <ol>
          <li>
            Натиснете струната, която се опитвате да настроите. Избраната струна
            ще се възпроизвежда на всеки 2 секунди, за да можете да се
            концентрирате върху инструмента си.
          </li>
          <li>Подобрявате музикалния си слух. Добра работа!</li>
        </ol>
        <br />
        <p>
          <strong>Професионален съвет:</strong> Уверете се, че сте добавили тази
          страница към отметките си, за да я намерите лесно следващия път!
        </p>
      </>
    ),
    ja: (
      <>
        <p>
          ギターをマイクまたは耳で調整できます。ギターを
          <strong>マイクで自動調整</strong>
          する方がはるかに簡単で早く、推奨する方法です。
          しかし、耳で楽器を調整することで、長期的には音感が向上し、オフライン時にも役立つスキルを
          学ぶことができます。
          <br />
          <br />
          ギターを<strong>自動で調整するには:</strong>
          <br />
        </p>
        <ol>
          <li>
            マイクのスイッチを押して、ギターを自動的に調整します。ウェブサイトがマイクの使用を許可していることを
            確認してください。
          </li>
          <li>
            調整器が演奏している弦を認識できるように、楽器をマイクの近くに置きます。
            <em>弦の検出</em>
            がオンになっている場合、ギター調整器は自動的に調整している弦を
            識別しようとします。あるいは、調整したい弦を押します。
          </li>
          <li>
            ギターの調整がこれほど簡単だったことはありません。調整された楽器を楽しんでください！
          </li>
        </ol>
        <br />
        <p>
          ギターを<strong>耳で調整するには:</strong>
        </p>
        <ol>
          <li>
            調整したい弦を押します。選択した弦は2秒ごとに再生されるので、楽器に集中することができます。
          </li>
          <li>音感が向上しています。素晴らしい仕事です！</li>
        </ol>
        <br />
        <p>
          <strong>プロのヒント:</strong>{" "}
          次回簡単に見つけられるよう、このページをブックマークしてください！
        </p>
      </>
    ),
    pt: (
      <>
        <p>
          Você pode afinar sua guitarra com um microfone ou de ouvido. Afinar a
          guitarra <strong>automaticamente com um microfone</strong> é muito
          mais fácil, rápido, e é a nossa opção recomendada. No entanto, afinar
          seu instrumento de ouvido melhorará seu ouvido musical a longo prazo e
          pode ser uma habilidade valiosa para aprender para os momentos em que
          você não está online.
          <br />
          <br />
          Para afinar sua <strong>guitarra automaticamente:</strong>
          <br />
        </p>
        <ol>
          <li>
            Pressione o botão do Microfone para afinar a guitarra
            automaticamente. Certifique-se de permitir que o site utilize seu
            microfone.
          </li>
          <li>
            Coloque seu instrumento próximo ao microfone para que o afinador
            reconheça a corda que você está tocando. Se a opção{" "}
            <em>Detectar Corda</em>
            estiver ativada, o afinador tentará identificar automaticamente a
            corda que você está afinando. Alternativamente, pressione a corda
            que você deseja afinar.
          </li>
          <li>
            Afinar sua guitarra nunca foi tão fácil. Aproveite seu instrumento
            bem afinado!
          </li>
        </ol>
        <br />
        <p>
          Para afinar sua <strong>guitarra de ouvido:</strong>
        </p>
        <ol>
          <li>
            Pressione a corda que você está tentando afinar. A corda selecionada
            tocará a cada 2 segundos para que você possa se concentrar em seu
            instrumento.
          </li>
          <li>Você está aprimorando seu ouvido musical. Bom trabalho!</li>
        </ol>
        <br />
        <p>
          <strong>Dica profissional:</strong> Certifique-se de adicionar esta
          página aos favoritos para encontrá-la facilmente na próxima vez!
        </p>
      </>
    ),
    tr: (
      <>
        <p>
          Gitarınızı mikrofonla veya kulaktan akort edebilirsiniz. Gitarı
          <strong>mikrofonla otomatik olarak akort etmek</strong> çok daha
          kolay, hızlıdır ve önerdiğimiz seçenektir. Ancak, enstrümanınızı
          kulaktan akort etmek, uzun vadede müzikal kulağınızı geliştirir ve
          çevrimdışı olduğunuzda öğrenebileceğiniz değerli bir beceri olabilir.
          <br />
          <br />
          <strong>Gitarınızı otomatik olarak akort etmek için:</strong>
          <br />
        </p>
        <ol>
          <li>
            Gitarı otomatik olarak akort etmek için Mikrofon düğmesine basın.
            Web sitesinin mikrofonunuzu kullanmasına izin verdiğinizden emin
            olun.
          </li>
          <li>
            Akort cihazının çaldığınız teli tanıyabilmesi için enstrümanınızı
            mikrofonun yakınına getirin. <em>Tel Algılama</em> açıksa, gitar
            akort cihazı, akort etmekte olduğunuz teli otomatik olarak tanımaya
            çalışacaktır. Alternatif olarak, akort etmek istediğiniz tele basın.
          </li>
          <li>
            Gitarı akort etmek hiç bu kadar kolay olmamıştı. İyi akort edilmiş
            enstrümanınızın keyfini çıkarın!
          </li>
        </ol>
        <br />
        <p>
          <strong>Gitarınızı kulaktan akort etmek için:</strong>
        </p>
        <ol>
          <li>
            Akort etmek istediğiniz tele basın. Seçilen tel her 2 saniyede bir
            çalınır, böylece enstrümanınıza odaklanabilirsiniz.
          </li>
          <li>Müzikal kulağınızı geliştiriyorsunuz. Tebrikler!</li>
        </ol>
        <br />
        <p>
          <strong>Profesyonel ipucu:</strong> Bir dahaki sefere kolayca
          bulabilmek için bu sayfayı yer imlerinize eklediğinizden emin olun!
        </p>
      </>
    ),
    vi: (
      <>
        <p>
          Bạn có thể chỉnh âm guitar của mình bằng micro hoặc bằng tai. Chỉnh âm
          guitar
          <strong>bằng micro tự động</strong> dễ dàng và nhanh chóng hơn nhiều,
          và đây là tùy chọn mà chúng tôi khuyến nghị. Tuy nhiên, chỉnh âm nhạc
          cụ bằng tai sẽ cải thiện thính giác âm nhạc của bạn trong dài hạn và
          có thể là một kỹ năng quý giá để học khi bạn không trực tuyến.
          <br />
          <br />
          Để chỉnh âm <strong>guitar của bạn tự động:</strong>
          <br />
        </p>
        <ol>
          <li>
            Nhấn công tắc Micro để chỉnh âm guitar tự động. Hãy chắc chắn rằng
            bạn đã cho phép trang web sử dụng micro của mình.
          </li>
          <li>
            Đặt nhạc cụ của bạn gần micro để bộ chỉnh âm nhận biết dây đàn mà
            bạn đang chơi. Nếu
            <em>Phát hiện dây</em> đang bật, bộ chỉnh âm guitar sẽ cố gắng tự
            động nhận dạng dây mà bạn đang chỉnh. Ngoài ra, hãy nhấn vào dây mà
            bạn muốn chỉnh.
          </li>
          <li>
            Chỉnh âm guitar của bạn chưa bao giờ dễ dàng như thế. Hãy tận hưởng
            nhạc cụ đã được chỉnh âm của bạn!
          </li>
        </ol>
        <br />
        <p>
          Để chỉnh âm <strong>guitar bằng tai:</strong>
        </p>
        <ol>
          <li>
            Nhấn vào dây mà bạn đang cố gắng chỉnh. Dây được chọn sẽ phát âm
            thanh mỗi 2 giây để bạn có thể tập trung vào nhạc cụ của mình.
          </li>
          <li>Bạn đang cải thiện thính giác âm nhạc của mình. Tốt lắm!</li>
        </ol>
        <br />
        <p>
          <strong>Mẹo chuyên nghiệp:</strong> Hãy đánh dấu trang này để dễ dàng
          tìm thấy lần sau!
        </p>
      </>
    ),
    tw: (
      <>
        <p>
          您可以使用麥克風或耳朵來調整您的吉他。使用麥克風自動調音吉他
          <strong>更容易、更快捷</strong>
          ，這是我們推薦的選擇。然而，長期來看，使用耳朵調音可以提高您的音樂聽覺，並且在您離線時學習這項技能可能會非常有價值。
          <br />
          <br />要<strong>自動調整您的吉他：</strong>
          <br />
        </p>
        <ol>
          <li>按下麥克風開關自動調音吉他。確保您已允許網站使用您的麥克風。</li>
          <li>
            將您的樂器靠近麥克風，讓調音器識別您正在演奏的弦。如果啟用了
            <em>檢測弦</em>
            ，吉他調音器將嘗試自動識別您正在調音的弦。或者，按下您要調音的弦。
          </li>
          <li>吉他的調音從未如此簡單。享受您的調音好的樂器吧！</li>
        </ol>
        <br />
        <p>
          要<strong>用耳朵調整您的吉他：</strong>
        </p>
        <ol>
          <li>
            按下您要調整的琴弦。選定的琴弦每2秒演奏一次，這樣您可以集中注意力在樂器上。
          </li>
          <li>您正在提升您的音樂聽覺。做得好！</li>
        </ol>
        <br />
        <p>
          <strong>專家提示：</strong>請務必將此頁添加書籤，以便下次輕鬆找到它！
        </p>
      </>
    ),
    pl: (
      <>
        <p>
          Możesz nastroić swoją gitarę za pomocą mikrofonu lub na słuch.
          Strojenie gitary
          <strong>automatycznie za pomocą mikrofonu</strong> jest znacznie
          łatwiejsze, szybsze i jest to opcja, którą polecamy. Jednak strojenie
          instrumentu na słuch poprawi twój słuch muzyczny na dłuższą metę i
          może być cenną umiejętnością do nauki, gdy nie masz dostępu do
          internetu.
          <br />
          <br />
          Aby nastroić gitarę <strong>automatycznie:</strong>
          <br />
        </p>
        <ol>
          <li>
            Naciśnij przełącznik Mikrofon, aby automatycznie nastroić gitarę.
            Upewnij się, że strona internetowa ma dostęp dotwojego mikrofonu.
          </li>
          <li>
            Przybliż instrument do mikrofonu, aby tuner rozpoznał strunę, którą
            grasz. Jeśli funkcja <em>Wykrywanie Struny</em> jest włączona, tuner
            gitarowy spróbuje automatycznie zidentyfikować strunę, którą
            stroisz. Alternatywnie, naciśnij strunę, którą chcesz nastroić.
          </li>
          <li>
            Strojenie gitary nigdy nie było takie proste. Ciesz się dobrze
            nastrojonym instrumentem!
          </li>
        </ol>
        <br />
        <p>
          Aby nastroić gitarę <strong>na słuch:</strong>
        </p>
        <ol>
          <li>
            Naciśnij strunę, którą chcesz nastroić. Wybrana struna będzie
            odtwarzana co 2 sekundy, abyś mógł skupić się na instrumencie.
          </li>
          <li>Poprawiasz swój słuch muzyczny. Dobra robota!</li>
        </ol>
        <br />
        <p>
          <strong>Wskazówka profesjonalisty:</strong> Upewnij się, że dodasz tę
          stronę do zakładek, aby łatwo ją znaleźć następnym razem!
        </p>
      </>
    ),
    it: (
      <>
        <p>
          Puoi accordare la tua chitarra con un microfono o a orecchio.
          Accordare la chitarra
          <strong>automaticamente con un microfono</strong> è molto più facile,
          veloce ed è l'opzione che consigliamo. Tuttavia, accordare il tuo
          strumento a orecchio migliorerà il tuo orecchio musicale a lungo
          termine e può essere una competenza preziosa da apprendere per i
          momenti in cui non sei online.
          <br />
          <br />
          Per accordare la tua <strong>chitarra automaticamente:</strong>
          <br />
        </p>
        <ol>
          <li>
            Premi l'interruttore del microfono per accordare automaticamente la
            chitarra. Assicurati di consentire al sito di utilizzare il tuo
            microfono.
          </li>
          <li>
            Avvicina il tuo strumento al microfono per consentire al
            sintonizzatore di riconoscere la corda che stai suonando. Se
            l'opzione <em>Rilevamento della Corda</em> è attiva, il
            sintonizzatore cercherà di identificare automaticamente la corda che
            stai accordando. In alternativa, premi la corda che desideri
            accordare.
          </li>
          <li>
            Accordare la tua chitarra non è mai stato così semplice. Goditi il
            tuo strumento ben accordato!
          </li>
        </ol>
        <br />
        <p>
          Per accordare la tua <strong>chitarra a orecchio:</strong>
        </p>
        <ol>
          <li>
            Premi la corda che stai cercando di accordare. La corda selezionata
            suonerà ogni 2 secondi in modo che tu possa concentrarti sul tuo
            strumento.
          </li>
          <li>Stai migliorando il tuo orecchio musicale. Ottimo lavoro!</li>
        </ol>
        <br />
        <p>
          <strong>Consiglio professionale:</strong> Assicurati di aggiungere
          questa pagina ai segnalibri per trovarla facilmente la prossima volta!
        </p>
      </>
    ),
    sv: (
      <>
        <p>
          Du kan stämma din gitarr med en mikrofon eller på gehör. Att stämma
          gitarren
          <strong>automatiskt med en mikrofon</strong> är mycket enklare,
          snabbare, och är vårt rekommenderade alternativ. Att stämma ditt
          instrument på gehör kommer dock att förbättra ditt musiköra på lång
          sikt och kan vara en värdefull färdighet att lära sig för de
          tillfällen när du inte är online.
          <br />
          <br />
          För att stämma din <strong>gitarr automatiskt:</strong>
          <br />
        </p>
        <ol>
          <li>
            Tryck på Mikrofon-knappen för att stämma gitarren automatiskt. Se
            till att tillåta webbplatsen att använda din mikrofon.
          </li>
          <li>
            För ditt instrument nära mikrofonen så att stämapparaten kan känna
            igen strängen du spelar. Om <em>Strängdetektering</em> är på, kommer
            stämapparaten försöka att automatiskt identifiera strängen du
            stämmer. Alternativt, tryck på strängen du vill stämma.
          </li>
          <li>
            Att stämma din gitarr har aldrig varit så enkelt. Njut av ditt
            välstämmda instrument!
          </li>
        </ol>
        <br />
        <p>
          För att stämma din <strong>gitarr på gehör:</strong>
        </p>
        <ol>
          <li>
            Tryck på strängen du försöker stämma. Den valda strängen spelas
            varannan sekund så att du kan koncentrera dig på ditt instrument.
          </li>
          <li>Du förbättrar ditt musiköra. Bra jobbat!</li>
        </ol>
        <br />
        <p>
          <strong>Proffstips:</strong> Se till att bokmärka denna sida för att
          enkelt hitta den nästa gång!
        </p>
      </>
    ),
    fi: (
      <>
        <p>
          Voit virittää kitarasi mikrofonilla tai korvakuulolta. Kitaran
          <strong>automaattinen virittäminen mikrofonilla</strong> on paljon
          helpompaa ja nopeampaa, ja se on suosituksemme. Viritämällä
          instrumenttisi korvakuulolta parannat musiikillista korvaasi pitkällä
          aikavälillä, ja se voi olla arvokas taito oppia, kun et ole verkossa.
          <br />
          <br />
          Virittääksesi <strong>kitarasi automaattisesti:</strong>
          <br />
        </p>
        <ol>
          <li>
            Paina Mikrofonikytkintä virittääksesi kitaran automaattisesti.
            Varmista, että verkkosivusto saa käyttää mikrofoniasi.
          </li>
          <li>
            Aseta instrumenttisi lähelle mikrofonia, jotta viritin tunnistaa
            soittamasi kielen. Jos <em>Kielen tunnistus</em> on päällä, kitaran
            viritin yrittää automaattisesti tunnistaa viritettävän kielen.
            Vaihtoehtoisesti paina kieltä, jonka haluat virittää.
          </li>
          <li>
            Kitaran virittäminen ei ole koskaan ollut näin helppoa. Nauti hyvin
            viritetystä instrumentistasi!
          </li>
        </ol>
        <br />
        <p>
          Virittääksesi <strong>kitarasi korvakuulolta:</strong>
        </p>
        <ol>
          <li>
            Paina viritettävää kieltä. Valittu kieli soi joka toinen sekunti,
            jotta voit keskittyä instrumenttiisi.
          </li>
          <li>Parannat musiikillista korvaasi. Hienoa työtä!</li>
        </ol>
        <br />
        <p>
          <strong>Ammattivinkki:</strong> Muista lisätä tämä sivu
          kirjanmerkkeihin, jotta löydät sen helposti seuraavalla kerralla!
        </p>
      </>
    ),
    hi: (
      <>
        <p>
          आप अपने गिटार को माइक्रोफ़ोन या कान से ट्यून कर सकते हैं। गिटार को{" "}
          <strong>माइक्रोफ़ोन के साथ स्वचालित रूप से ट्यून करना</strong>
          बहुत आसान, तेज़ है और यह हमारा अनुशंसित विकल्प है। हालाँकि, अपने
          वाद्ययंत्र को कान से ट्यून करना लंबे समय में आपके संगीत कान को सुधार
          देगा, और यह एक मूल्यवान कौशल हो सकता है जिसे आप ऑफलाइन रहते समय सीख
          सकते हैं।
          <br />
          <br />
          <strong>अपने गिटार को स्वचालित रूप से ट्यून करने के लिए:</strong>
          <br />
        </p>
        <ol>
          <li>
            गिटार को स्वचालित रूप से ट्यून करने के लिए माइक्रोफ़ोन स्विच को
            दबाएं। सुनिश्चित करें कि आपने वेबसाइट को अपने माइक्रोफ़ोन का उपयोग
            करने की अनुमति दी है।
          </li>
          <li>
            अपने वाद्ययंत्र को माइक्रोफ़ोन के पास रखें ताकि ट्यूनर आपके द्वारा
            बजाई जा रही स्ट्रिंग को पहचान सके। यदि{" "}
            <em>स्ट्रिंग का पता लगाना</em> चालू है, तो गिटार ट्यूनर स्वचालित रूप
            से उस स्ट्रिंगको पहचानने का प्रयास करेगा जिसे आप ट्यून कर रहे हैं।
            वैकल्पिक रूप से, उस स्ट्रिंग को दबाएं जिसे आप ट्यून करना चाहते हैं।
          </li>
          <li>
            गिटार का ट्यूनिंग करना पहले कभी इतना आसान नहीं था। अपने अच्छी तरह से
            ट्यून किए गए वाद्ययंत्र का आनंद लें!
          </li>
        </ol>
        <br />
        <p>
          <strong>कान से गिटार ट्यून करने के लिए:</strong>
        </p>
        <ol>
          <li>
            उस स्ट्रिंग को दबाएं जिसे आप ट्यून करना चाहते हैं। चयनित स्ट्रिंग हर
            2 सेकंड में एक बार बजेगी ताकि आप अपने वाद्ययंत्र पर ध्यान केंद्रित
            कर सकें।
          </li>
          <li>आप अपने संगीत कान को सुधार रहे हैं। अच्छा काम!</li>
        </ol>
        <br />
        <p>
          <strong>प्रो टिप:</strong> अगली बार इस पेज को आसानी से खोजने के लिए
          इसे बुकमार्क करना न भूलें!
        </p>
      </>
    ),
  },
};

export default home;
