export default function CommonHeadTags() {
  return (
    <>
      <link rel='icon' href='/favicon.ico' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, user-scalable=no'
      ></meta>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2051422132452574" ccrossorigin="anonymous"></script>
    </>
  )
}
